import React from 'react';
import './ImageSection.css';
import ParticlesBackground from '../ParticlesBackground/ParticlesBackground';


const ImageSection = () => {
  return (
    <div className="image-section">
      <ParticlesBackground className="full-image" />
    </div>
  );
};

export default ImageSection;
