import React, { useEffect, useRef } from 'react';
import p5 from 'p5';
import './MaskedFace.css';
import staticImage from '../../assets/about-person.png';

const MaskedFace = () => {
  const sketchRef = useRef();

  useEffect(() => {
    let myp5;

    new p5(p => {
      let yoff = 0.0;

      p.setup = () => {
        p.createCanvas(sketchRef.current.offsetWidth, sketchRef.current.offsetHeight);
        p.background(255);
      };

      p.draw = () => {
        p.background(255);
        p.fill(50);
        p.beginShape();
        
        let xoff = 0;
        
        for (let x = 0; x <= p.width; x += 10) {
          let y = p.map(p.noise(xoff, yoff), 0, 1, 200, 300);
          p.vertex(x, y);
          xoff += 0.05;
        }
        
        yoff += 0.01;
        p.vertex(p.width, p.height);
        p.vertex(0, p.height);
        p.endShape(p.CLOSE);

        myp5 = p;
      };
    }, sketchRef.current);



    return () => {
      if (myp5) {
        myp5.remove(); 
      }
    };
  }, []);

  return (
    <div className="masked-face-container">
      <div ref={sketchRef} className="animated-background"></div>
      <img src={staticImage} alt="Masked Face" className="static-image" />
    </div>
  );
};

export default MaskedFace;
