// Writing.js

import React, { useState } from 'react';
import BaseLayout from '../BaseLayout';
import WritingPiece from '../Components/WritingPiece/WritingPiece';
import './Writing.css';
import ImageSection from '../Components/ParticleImageSection/ImageSection';

// Dummy data for writing pieces
const writings = [
  { id: 1, title: 'Title One', content: 'Content for title one...' },
  { id: 2, title: 'Title Two', content: 'Content for title two...' },

  { id: 3, title: 'Title Three', content: 'Content for title three...' },

  { id: 4, title: 'Title Four', content: 'Content for title four...' },

];

const Writing = () => {
  const [openId, setOpenId] = useState(null);

  const toggleOpen = (id) => {
    if (openId === id) {
      setOpenId(null); // If it's already open, close it
    } else {
      setOpenId(id); // Open the clicked one
    }
  };

  return (
    <BaseLayout>
    <div className="writing-background-container">
    <ImageSection />

      <div className="writing-container">
        {writings.map((writing) => (
          <WritingPiece
            key={writing.id}
            title={writing.title}
            content={writing.content}
            isOpen={openId === writing.id}
            toggleOpen={() => toggleOpen(writing.id)}
          />
        ))}
      </div>
      </div>
    </BaseLayout>
  );
};

export default Writing;
