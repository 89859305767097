import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import './ProjectPair.css'; 

const ProjectPair = ({ project }) => {
    const navigate = useNavigate(); 

    const navigateToProject = () => {
      navigate(`/projects/${project.id}`); 
    };

  return (
    <div className="project-pair">
      <div className="project-media" onClick={navigateToProject}>
        {project.type === 'video' ? (
          <video src={project.mediaUrl} />
        ) : (
          <img src={project.mediaUrl} alt={project.title} />
        )}
      </div>
      <div className="project-description">
        <h3>{project.title}</h3>
        <p>{project.description}</p>
      </div>
    </div>
  );
};

export default ProjectPair;
