import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import HomePage from './Pages/HomePage.js';
import Research from './Pages/Research.js';
import Writing from './Pages/Writing.js';
import About from './Pages/About.js';
import UTT from './Pages/UTT.js';
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';

function App() {
  return (
    <Router>
      <ScrollToTop >
      <div className="App">
        <Routes>
          <Route path="/about" element={<About title="About" />} />
          <Route path="/tech" element={<UTT title="Technologies"/>} />
          <Route path="/research" element={<Research title="Research" />} />
          <Route path="/writing" element={<Writing title="Writing"/>} />
          <Route exact path="/" element={<HomePage title="UPTACK"/>} />
        </Routes>
      </div>
      </ScrollToTop>
    </Router>
  );
}

export default App;
