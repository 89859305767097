import React from 'react';
import './About.css';
import BaseLayout from '../BaseLayout';
import ImageSection from '../Components/ParticleImageSection/ImageSection';
import Text2Section from '../Test2Section';
import MaskedFace from '../Components/MaskedFace/MaskedFace';


const About = () => {
  return (
    <BaseLayout>
        <div className="overlay-container">
          <ImageSection />
          <MaskedFace />
          <Text2Section />
        </div>
    </BaseLayout>
  );
};

export default About;
