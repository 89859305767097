import React from 'react';
import BaseLayout from '../BaseLayout';
import ProjectPair from '../Components/ProjectPair/ProjectPair'; 
import './UTT.css'; 
import ImageSection from '../Components/ParticleImageSection/ImageSection'; 

// Dummy data for the projects
const projects = [
  {
    id: 1,
    type: 'image', // or 'video'
    mediaUrl: 'rick-ashley-dance.gif',
    title: 'Projects coming soon!',
    description: 'An example of the project desc setup. Currently only dance.',
    
  },

];

const UTT = () => {
  return (
    <BaseLayout>
      <div className="tech-background-container">
        <ImageSection />
        <div className="projects-container">
          {projects.map((project) => (
            <ProjectPair key={project.id} project={project} />
          ))}
        </div>
      </div>
    </BaseLayout>
  );
};

export default UTT;

