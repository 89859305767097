import React, { useState } from 'react';
import './WritingPiece.css'; 

const WritingPiece = ({ title, content, isOpen, toggleOpen }) => {
    return (
      <div className={`writing-piece ${isOpen ? 'open' : ''}`} onClick={toggleOpen}>
        <div className="title">{title}</div>
        {isOpen && <div className="content">{content}</div>}
      </div>
    );
  };
  

export default WritingPiece;
