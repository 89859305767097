import React from 'react';
import './Test2Section.css';

const Text2Section = () => {
    return (
        <div className='text-box'>
        <p>AHHHHHHHHHHHHHHHHHHHH</p>
        <p>AHHHHHHHHHHHHHHHHHHHH</p>

        <p>AHHHHHHHHHHHHHHHHHHHH</p>

        <p>AHHHHHHHHHHHHHHHHHHHH</p>

        <p>AHHHHHHHHHHHHHHHHHHHH</p>

        <p>AHHHHHHHHHHHHHHHHHHHH</p>

        <p>AHHHHHHHHHHHHHHHHHHHH</p>
        <p>AHHHHHHHHHHHHHHHHHHHH</p>
        <p>AHHHHHHHHHHHHHHHHHHHH</p>
        <p>AHHHHHHHHHHHHHHHHHHHH</p>
        <p>AHHHHHHHHHHHHHHHHHHHH</p>
        <p>AHHHHHHHHHHHHHHHHHHHH</p>
        <p>AHHHHHHHHHHHHHHHHHHHH</p>
        <p>AHHHHHHHHHHHHHHHHHHHH</p>
        <p>AHHHHHHHHHHHHHHHHHHHH</p>
        <p>AHHHHHHHHHHHHHHHHHHHH</p>
        <p>AHHHHHHHHHHHHHHHHHHHH</p>
</div>
        );
};

export default Text2Section;